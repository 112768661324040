import { GetStaticProps, NextPage } from 'next'
import { Layout } from '../components/layout/layout'
import { GlobalProps, withLayoutProps } from '../globals/layout'
import { Error } from '../templates/error'
import { StyledErrorWrapper } from './404'

export type InternalServerErrorPageProps = GlobalProps & {

}

const InternalServerErrorPage: NextPage<InternalServerErrorPageProps> = ({ ...layoutProps }) => {
  return (
    <StyledErrorWrapper>
      <Layout { ...layoutProps }>
        <Error statusCode={'500'} />
      </Layout>
    </StyledErrorWrapper>
  )
}

export default InternalServerErrorPage

export const getStaticProps: GetStaticProps = async () => {
  return withLayoutProps({
    path: '/',
  })
}
